.content {
  padding: 20px 15px 20px;

  &_header {
    display: flex;
    justify-content: space-between;
  }

  //&_body {
  //  margin-top: 25px;
  //}
}

.chips {
  align-items: center;
  display: flex;

  &_stack {
    margin: 0 20px 0 40px;
  }
}
