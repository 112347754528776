body,
html {
  background: radial-gradient(50% 50% at 50% 50%, #262524 0%, #181715 52.08%, #040404 100%);
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  max-width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#root {
  min-height: 100vh !important;
}

a {
  color: #FFF;
  outline: none;
  text-decoration: none;
}

// Стили карты Dashboard Google map //

#map {
  height: 519px;
  left: 0;
  position: absolute;
  right: 0;
  top: 57px;
  z-index: 0;

  & .gmnoprint a,
  & .gmnoprint span,
  & .gmnoprint div:not(:first-child) {
    display: none !important;
  }

  & div div a div img,
  .gm-style .gm-style-cc a, .gm-style .gm-style-cc div {
    display: none !important;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
  }

  & .gmnoprint div {
    background-color: transparent !important;
  }

  & .gmnoprint div button {
    background-color: #3F3F3F !important;
    border: 1px solid #FF9800 !important;
    border-radius: 4px !important;

    &:before {
      background-color: #FF9800;
      content: '';
      height: 3px;
      left: 9px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      z-index: 2;
    }

    &:after {
      background-color: #FF9800;
      content: '';
      height: 19px;
      left: 50%;
      position: absolute;
      top: 9.5px;
      transform: translateX(-50%);
      width: 3px;
      z-index: 2;
    }

  }

  & .gmnoprint div button:last-child {
    top: 10px !important;

    &:after {
      display: none;
    }
  }
}


// Стили Pie Charts на странице Analysis //

#alertPieChart,
#eventPieChart,
#vehiclePieChart {
  height: 430px;
  width: 100%;
}

// Стили Pie Charts на странице Dashboard //

#dashboardPieChart {
  height: 190px;
  margin-top: 10px;
  width: 100%;
}

// Стили XY Chart (Timeline) на странице Dashboard //

#timelineAll,
#timelineDay,
#timelineWeek {
  height: 200px;
  width: 500px;

  & svg tspan {
    fill: #949494;
  }

}

// Стили выпадаюшего списка Select (Autocomplete) //

.MuiAutocomplete-popper, .MuiCalendarPicker-root {
  background-color: #181715;
}

.css-1cyuqcn-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #181715 !important;
}
